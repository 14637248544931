import { HealthProgressLookupQueryQuery } from '@app/data';
import { healthRouteMap } from '@app/navigate';
import { HealthApplicationStatus } from '@app/types';

const statusWeights: Record<HealthApplicationStatus, number> = {
  // the resumable app statuses
  ENROLLMENT_STUCK: 10,
  ENROLLED: 9,
  SUBMITTED: 8,
  IN_PROGRESS: 7,
  DRAFT: 6,

  // not sure about these -- let's weight them lowest
  PENDING_ENROLLMENT: 0,
  DELETED: 0,
  CANCELED: 0,
  EXPLORER: 0,
  RENEWAL: 0,
};

const getProgress = (app) => {
  const healthRoutes = Object.keys(healthRouteMap);

  // use this if last used route is not defined
  const defaultLastUsedRoute = ['SUBMITTED', 'ENROLLED'].includes(app?.status)
    ? 'results'
    : 'intro';

  const lastUsedRoute = app?.lastUsedRoute || defaultLastUsedRoute;
  const index = healthRoutes.indexOf(lastUsedRoute);
  return index;
};

/**
 * Given a list of health applications, chooses the best one:
 *
 * 1) prioritize later coverage year (e.g. oe year 2023 vs. sep year 2022)
 * 2) Prioritize app status (SUBMITTED over IN_PROGRESS)
 * 3) Prioritize last used route (take the application farthest along in application)
 */
export const chooseHealthApp = (
  apps: HealthProgressLookupQueryQuery['viewerTwo']['health']['applications'],
) => {
  if (!apps || apps.length === 0) return undefined;

  const sorted = [...apps]?.sort((a, b) => {
    return (
      b.coverageYearNumber - a.coverageYearNumber ||
      statusWeights[b.status] - statusWeights[a.status] ||
      getProgress(a) - getProgress(b)
    );
  });

  return sorted?.[0];
};
