import React, { useState, useMemo, useEffect, ReactNode } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from '@react-spring/web';
import { RenderedIcon, Action, Copy, AssetProps } from '@types';
import { ButtonGroup, Page, useLayoutContext } from '@layouts';
import { Button, CatchTheme, Loading, UIAccentColor } from '@app/_ui-kit';
import { PromptCard } from '@app/_common';

interface ConfirmationProps {
  called: boolean;
  loading: boolean;
  error: boolean;
  accentColor?: UIAccentColor;
  titles?: {
    loading?: Copy;
    done?: Copy;
    error?: Copy;
  };
  subtitles?: {
    loading?: Copy;
    done?: Copy;
    error?: Copy;
  };
  followUp?: {
    asset?: AssetProps;
    accentColor?: UIAccentColor;
    render?: RenderedIcon;
    title: string;
    subtitle?: string;
    action: Action;
    loading?: boolean;
  };
  onSuccess?: () => void;
  icon?: string;
  iconStatus?: any;
  children?: ReactNode;
  delay?: typeof CatchTheme.animation.delayMedium | typeof CatchTheme.animation.delayMedium;
}

const AnimatedDiv = animated?.div;

const PROMPT_DELAY = CatchTheme.animation.delayMedium;
const SUCCESS_DELAY = CatchTheme.animation.delayMedium;

export const ConfirmationBlueprint: React.FC<ConfirmationProps> = ({
  called,
  loading,
  error,
  titles,
  subtitles,
  accentColor,
  followUp,
  onSuccess,
  children,
  icon,
  iconStatus,
  delay = SUCCESS_DELAY,
}) => {
  const [dismissed, setDismissed] = useState(false);
  const { layout } = useLayoutContext();

  const invoked = useMemo(() => {
    return called;
  }, [called, loading, error]);

  const done = useMemo(() => {
    return called && !error && !loading;
  }, [called, loading, error]);

  const showPrompt = useMemo(() => {
    return done && !!followUp;
  }, [done, followUp]);

  useEffect(() => {
    if (done && onSuccess && !followUp) {
      setTimeout(onSuccess, delay);
    }
  }, [done, onSuccess]);

  const loaderBox = useSpring({
    translateY: showPrompt ? -150 : 0,
    delay: showPrompt ? PROMPT_DELAY : 0,
  });

  const promptBox = useSpring({
    translateY: showPrompt ? 0 : 300,
    scale: dismissed ? 0.9 : 1,
    opacity: dismissed ? 0 : done ? 1 : 0,
    delay: showPrompt && !dismissed ? SUCCESS_DELAY : 0,
  });

  if (!invoked) {
    return <>{children}</>;
  }

  return (
    <Page color={layout}>
      <AnimatedDiv style={{ flex: 1, transform: loaderBox.translateY }}>
        <Loading<'done' | 'loading' | 'error'>
          full
          accentColor={accentColor}
          status={done ? 'done' : error ? 'error' : 'loading'}
          titles={{ loading: ' ', done: ' ', ...titles }}
          subtitles={{ error: 'Something went wrong', ...subtitles }}
          icon={icon}
          iconStatus={iconStatus}
        />
      </AnimatedDiv>
      <PromptContainer
        style={{
          opacity: promptBox.opacity,
          transform: `translateY(${promptBox.translateY}px) scale(${promptBox.scale})`,
        }}
      >
        {followUp && (
          <PromptCard
            title={followUp.title}
            subtitle={followUp.subtitle}
            render={followUp.render}
            asset={followUp.asset}
            gradient={followUp.accentColor + 'Light'}
            wide
          >
            <div style={{ marginLeft: 16, marginRight: 16, marginBottom: 16 }}>
              <ButtonGroup>
                <Button
                  testID={`dismiss-success-prompt`}
                  alt
                  accentColor={followUp.accentColor ? followUp.accentColor + 'Light' : 'grayBg'}
                  onClick={() => {
                    setDismissed(true);
                    onSuccess();
                  }}
                >
                  Not now
                </Button>
                <Button
                  testID={followUp.action?.testID || 'agree-success-prompt'}
                  accentColor={followUp.accentColor}
                  onClick={followUp.action?.onAction}
                  loading={followUp.loading}
                >
                  {followUp.action.label}
                </Button>
              </ButtonGroup>
            </div>
          </PromptCard>
        )}
      </PromptContainer>
    </Page>
  );
};

const PromptContainer = styled.div`
  position: absolute;
  align-items: center;
  bottom: ${CatchTheme.spacing.mobile.margins}px;
  left: ${CatchTheme.spacing.mobile.margins}px;
  right: ${CatchTheme.spacing.mobile.margins}px;
`;

export default ConfirmationBlueprint;
