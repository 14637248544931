import React from 'react';
import { Copy } from '@types';
import { Layout, useLayoutContext } from '@layouts';
import { useTheme } from '../hooks/useTheme';
import { UIAccentColor } from '../types';
import { Asset, Block, Spinner } from '@uikit';
import { SPINNER_ASSET_PROPS } from '@app/_ui-kit/components/Spinner';

const ICON_COLORS = {
  negative: {
    bg: 'negativeLight',
    color: 'negative',
  },
  neutral: {
    bg: 'mediumLight',
    color: 'medium',
  },
};

interface LoadingProps<T extends string> {
  accentColor?: UIAccentColor;
  status?: T;
  titles?: Record<T, Copy>;
  subtitles?: Record<T, Copy>;
  full?: boolean;
  page?: boolean;
  style?: object;
  icon?: string;
  iconStatus?: 'negative' | 'neutral';
}

export const Loading = <T extends string>({
  status,
  titles,
  subtitles,
  style,
  full,
  page,
  accentColor,
  icon,
  iconStatus,
}: LoadingProps<T>) => {
  const { theme } = useTheme();
  const context = useLayoutContext();
  const title = status && titles ? titles[status] : undefined;
  const subtitle = status && subtitles ? subtitles[status] : undefined;

  const accessibleText =
    status === 'done'
      ? 'Content loaded successfully.'
      : status === 'error'
      ? 'There was an error loading the content.'
      : 'Loading';

  return (
    <Layout
      margins
      style={[
        !!page && theme.pageBg,
        styles.container,
        full && styles.fullHeight,
        theme.bottomSpace6,
        style,
      ]}
    >
      <Block style={theme.yGutter3} role="status" aria-live="polite" aria-atomic="true">
        {icon ? (
          <Asset
            svg={icon}
            {...SPINNER_ASSET_PROPS}
            bg={ICON_COLORS[iconStatus || 'neutral'].bg}
            color={ICON_COLORS[iconStatus || 'neutral'].color}
            aria-label={accessibleText}
          />
        ) : (
          <Spinner
            color={accentColor || context?.accentColor}
            done={status === 'done'}
            error={status === 'error'}
            large
            aria-label={accessibleText}
          />
        )}
        <span className="sr-only">{accessibleText}</span>
      </Block>
      {(!!title || !!subtitle) && (
        <Layout.Header light title={title} subtitle={subtitle} align="center" />
      )}
    </Layout>
  );
};

const styles = {
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  fullHeight: {
    height: '100%',
  },
};

export default Loading;
