// icon imports - alpha ordered
import ArrowRight from '@svg/arrow-right.svg';
import Back from '@svg/chevron-left.svg';
import Chat from '@svg/annotation.svg';
import Clock from '@svg/clock.svg';
import Close from '@svg/close.svg';
import CloseCircle from '@svg/close-circle.svg';
import Command from '@svg/command.svg';
import Globe from '@svg/globe.svg';
import Help from '@svg/help-circle.svg';
import Info from '@svg/information.svg';
import Menu from '@svg/menu.svg';
import Minus from '@svg/minus.svg';
import More from '@svg/dots-horizontal.svg';
import Plus from '@svg/plus.svg';
import Share from '@svg/share.svg';
import Sort from '@svg/sort-descending.svg';

export const icons = {
  ArrowRight,
  Back,
  Chat,
  Clock,
  Close,
  CloseCircle,
  Command,
  Globe,
  Help,
  Info,
  Menu,
  Minus,
  More,
  Plus,
  Share,
  Sort,
};
