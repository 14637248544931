import React from 'react';
import styled from 'styled-components';

import { Env, formatISO, useCopy } from '@app/utils';
import { ExplainerIntroBlueprint } from '@blueprints';
import { Layout, Toolbar, Section, Stack } from '@layouts';
import { Button, Text, Accordion, Banner } from '@uikit';

import { useHealthStart } from '@app/hooks';
import { navigate } from '@app/navigate';
import { FontSize, Route } from '@types';

import Money from '@svg/dollar-2.svg';
import Plan from '@svg/gallery-view.svg';
import Shield from '@svg/shield-tick.svg';
import Pencil from '@svg/pencil.svg';
import Message from '@svg/message-1.svg';
import Checkmark from '@svg/checkmark.svg';

const stateAppBullets = [
  { copy: 'health.intro.details', icon: Pencil },
  { copy: 'health.intro.review', icon: Message },
  { copy: 'health.intro.submit', icon: Checkmark },
];

const config = {
  CA: {
    marketplace: 'Covered California',
    banner: 'health.intro.state-app-banner',
    bullets: stateAppBullets,
    faqs: ['health.faq.state-app-next', 'health.faq.ca-overview', 'health.faq.state-app-benefits'],
  },
  NJ: {
    marketplace: 'Get Covered NJ',
    banner: 'health.intro.state-app-banner',
    bullets: stateAppBullets,
    faqs: ['health.faq.state-app-next', 'health.faq.nj-overview', 'health.faq.state-app-benefits'],
  },
  PA: {
    marketplace: 'Pennie',
    banner: 'health.intro.state-app-banner',
    bullets: stateAppBullets,
    faqs: ['health.faq.state-app-next', 'health.faq.pa-overview', 'health.faq.state-app-benefits'],
  },
  KY: {
    marketplace: 'Kentucky',
    banner: 'health.intro.state-app-banner',
    bullets: stateAppBullets,
    faqs: ['health.faq.state-app-next', 'health.faq.state-app-benefits'],
  },
  GA: {
    marketplace: 'Georgia Access',
    bullets: [
      { copy: 'health.intro.savings', icon: Money },
      { copy: 'health.intro.plan', icon: Plan },
      { copy: 'health.intro.enroll', icon: Shield },
    ],
    faqs: [
      'health.faq.ga-overview',
      'health.faq.ga-benefits',
      'health.faq.ga-apply',
      'health.faq.ga-medicaid',
      'health.faq.ga-notices',
      'health.faq.ga-redirect',
      'health.faq.ga-partner',
      'health.faq.ga-dental',
    ],
  },
  FFM: {
    marketplace: 'the Health Insurance Marketplace',
    bullets: [
      { copy: 'health.intro.savings', icon: Money },
      { copy: 'health.intro.plan', icon: Plan },
      { copy: 'health.intro.enroll', icon: Shield },
    ],
    faqs: [
      'health.faq.marketplace-overview',
      'health.faq.marketplace-benefits',
      'health.faq.marketplace-apply',
      'health.faq.marketplace-medicaid',
      'health.faq.marketplace-notices',
      'health.faq.marketplace-redirect',
    ],
  },
  STATE_APP: {
    marketplace: 'the Health Insurance Marketplace',
    bullets: stateAppBullets,
    faqs: [
      'health.faq.state-app-next',
      'health.faq.marketplace-overview',
      'health.faq.marketplace-benefits',
    ],
  },
  fallback: {
    marketplace: 'the Health Insurance Marketplace',
    bullets: [
      { copy: 'health.intro.savings', icon: Money },
      { copy: 'health.intro.plan', icon: Plan },
      { copy: 'health.intro.enroll', icon: Shield },
    ],
    faqs: [
      'health.faq.marketplace-overview',
      'health.faq.marketplace-benefits',
      'health.faq.marketplace-apply',
      'health.faq.marketplace-medicaid',
      'health.faq.marketplace-notices',
      'health.faq.marketplace-redirect',
    ],
  },
};

const getConfig = ({ pathway, coverageState }) => {
  if (coverageState in config) {
    return config[coverageState];
  }

  if (pathway in config) {
    return config[pathway];
  }

  return config.fallback;
};

const StyledText = styled(Text)`
  padding-bottom: 16px;
  text-align: left;
  display: block;
`;

/**
 *
 * Handles the health intro view
 *
 * Order of operations:
 * if an enroll id is set on params/route state, use that
 * if any explorer data exists, create enrollment from explorer
 * otherwise, continue to coverage info and upsert there
 *
 */
export const HealthIntro = ({ loading }) => {
  const { c } = useCopy('catch');

  const {
    loading: starting,
    creating,
    handleNext,
    foundApp,
    coverageState,
    pathway,
    cutoffs,
  } = useHealthStart({
    defaultRoute: Route.EDE_COVERAGE_INFO,
  });

  const canCreateNew = foundApp && !loading && !Env.isProd;

  const { bullets, faqs, banner, marketplace } = getConfig({ coverageState, pathway });

  return (
    <ExplainerIntroBlueprint
      loading={loading || starting}
      context="page"
      title={c('plans.PlanIntroView.healthApplication')}
      color="coverage"
      render="coverage"
      gradient="coverageLight"
      bullets={bullets.map((bullet, idx) => ({
        id: idx,
        label: c(bullet.copy),
        svg: bullet.icon,
      }))}
      toolbar={
        <Toolbar narrow type="stack">
          {canCreateNew && (
            <Button
              testID="create-new"
              wide
              onPress={() => navigate(Route.EDE_COVERAGE_INFO)}
              disabled={loading || creating}
              loading={loading}
              alt
            >
              Create New
            </Button>
          )}
          <Button
            testID="continue"
            wide
            onPress={handleNext}
            disabled={loading || creating}
            loading={loading}
          >
            Continue
          </Button>
        </Toolbar>
      }
    >
      <Layout topSpace>
        <Section
          title={c('health.intro.title')}
          subtitle={c('health.intro.subtitle', { marketplace })}
        >
          {banner && (
            <Banner
              testID="state-app-banner"
              title={c(banner, {
                applyBy: formatISO(cutoffs?.submitBy, 'MMMM do'),
                finalizedBy: formatISO(cutoffs?.applicationDeadline, 'MMMM do'),
                coverageStart: formatISO(cutoffs?.coverageStarts, 'MMMM do'),
              })}
            />
          )}
          <Stack separatorComponent>
            {faqs.map((copy, i) => (
              <Accordion key={i} title={c(`${copy}.question`)} small>
                <StyledText size={FontSize.fp} color="secondary">
                  {c(`${copy}.answer`)}
                </StyledText>
              </Accordion>
            ))}
          </Stack>
        </Section>
      </Layout>
    </ExplainerIntroBlueprint>
  );
};

export const HealthIntroView = {
  name: Route.EDE_INTRO,
  component: HealthIntro,
  options: {
    drawBehindNav: true,
  },
};
