import { differenceInDays, endOfYesterday, isWithinInterval, parseISO } from 'date-fns';
import { boolToTri, ninetyDaysAgo } from '@app/utils';

type Trinary = 'YES' | 'NO' | 'NIL';

type Radio = 'YES' | 'NO';

interface FormValues {
  id: string;
  relation?: 'SELF' | 'SPOUSE' | 'CHILD';
  givenName?: string;
  fullName?: string;
  isMedicaidDenied?: Radio;
  medicaidDeniedDate?: string;
  isAppliedDuringOe?: Radio;
  isAppliedDuringLifeChange?: Radio;
  isMedicaidEnd?: Radio;
  isHouseholdChanged?: Radio;
  isMedicaidDeniedDueToImmigration: Radio;
  hasImmigrationStatusFiveYear: Radio;
  isImmigrationStatusChangeSinceDenied: Radio;
  medicaidEndDate?: string;
  isLawfulPresenceStatus?: boolean;
}

interface PayloadValues {
  variables: {
    input: {
      applicationID: string;
      applicationMembersInput: Array<{
        id: string;
        isMedicaidEnd: Trinary;
        medicaidEndDate: Date;
        isWillLoseCoverageNext60Days?: Trinary;
        isLostCoverageLast60Days?: Trinary;
        coverageLostDate?: Date;
        coverageLostDateFuture?: Date;
        isMedicaidDenied: Trinary;
        medicaidDeniedDate: Date;
        isInfoChangeSinceMedicaidEnded: Trinary;
        isAppliedDuringOeOrLifeChange: Trinary;
        isMedicaidDeniedDueToImmigration: Trinary;
        hasImmigrationStatusFiveYear: Trinary;
        isImmigrationStatusChangeSinceDenied: Trinary;
      }>;
    };
  };
}

/**
 * Takes in raw health application data and creates initial form values
 */
export const getInitialValues = (member): FormValues => {
  return {
    id: member?.id,
    relation: member?.relation,
    givenName: member?.givenName,
    fullName: member?.legalName,
    isMedicaidEnd: boolToTri(member?.isMedicaidEnd),
    medicaidEndDate: member?.medicaidEndDate,
    isMedicaidDenied: boolToTri(member?.isMedicaidDenied),
    medicaidDeniedDate: member?.medicaidDeniedDate,
    isAppliedDuringOe: undefined,
    isAppliedDuringLifeChange: undefined,
    isHouseholdChanged: boolToTri(member?.isInfoChangeSinceMedicaidEnded),
    isMedicaidDeniedDueToImmigration: boolToTri(member?.isMedicaidDeniedDueToImmigration),
    hasImmigrationStatusFiveYear: boolToTri(member?.hasImmigrationStatusFiveYear),
    isImmigrationStatusChangeSinceDenied: boolToTri(member?.isImmigrationStatusChangeSinceDenied),
    isLawfulPresenceStatus: member?.isLawfulPresenceStatus,
  };
};

// updated to handle new indicators
export const formatPayload = ({ applicationID, values }): PayloadValues => {
  // check how many days to the medicaid end date when denoted that medicaid is ending/has ended
  const difference = differenceInDays(new Date(), new Date(values?.medicaidEndDate));
  const isWithinLast60Days = values?.isMedicaidEnd === 'YES' && difference <= 60 && difference >= 0;
  const isWithinNext60Days = values?.isMedicaidEnd === 'YES' && difference < 0 && difference >= -60;

  const appliedDuringEither =
    values?.isAppliedDuringOe === 'YES' || values?.isAppliedDuringLifeChange === 'YES'
      ? 'YES'
      : 'NO';

  // CR63 calculations for unwinding SEP period
  const timeframe = { start: new Date(ninetyDaysAgo()), end: endOfYesterday() }; // from 3/31 to yesterday

  const isUnwindingSEP =
    values?.isMedicaidEnd === 'YES' &&
    isWithinInterval(parseISO(values?.medicaidEndDate), timeframe);

  return {
    variables: {
      input: {
        applicationID,
        applicationMembersInput: [
          {
            id: values.id,
            isMedicaidEnd: values?.isMedicaidEnd,
            medicaidEndDate: values?.isMedicaidEnd === 'YES' ? values?.medicaidEndDate : null,
            isWillLoseCoverageNext60Days: isWithinNext60Days ? 'YES' : undefined,
            coverageLostDateFuture: isWithinNext60Days ? values?.medicaidEndDate : undefined,
            isLostCoverageLast60Days: isWithinLast60Days || isUnwindingSEP ? 'YES' : undefined,
            coverageLostDate:
              isWithinLast60Days || isUnwindingSEP ? values?.medicaidEndDate : undefined,
            isInfoChangeSinceMedicaidEnded:
              values?.isMedicaidEnd === 'YES' && values?.medicaidEndDate
                ? values?.isHouseholdChanged
                : 'NIL',
            isMedicaidDenied: values?.isMedicaidDenied === 'YES' ? 'YES' : 'NO',
            medicaidDeniedDate:
              values?.isMedicaidDenied === 'YES' ? values?.medicaidDeniedDate : null,
            isAppliedDuringOeOrLifeChange:
              values?.isMedicaidDenied === 'YES' ? appliedDuringEither : 'NIL',
            isMedicaidDeniedDueToImmigration: values?.isMedicaidDeniedDueToImmigration || 'NIL',
            hasImmigrationStatusFiveYear: values?.hasImmigrationStatusFiveYear || 'NIL',
            isImmigrationStatusChangeSinceDenied:
              values?.isImmigrationStatusChangeSinceDenied || 'NIL',
          },
        ],
      },
    },
  };
};
