import React from 'react';
import styled from 'styled-components';
import { Color } from '@app/styles';
import { IconLabel } from './IconLabel';
import { useLayoutContext } from '@app/layouts';
import { icons } from './iconAssets';

const sizes = {
  mini: {
    icon: 16,
    container: 24,
  },
  small: {
    icon: 20,
    container: 32,
  },
  regular: {
    icon: 24,
    container: 40,
  },
};

export type IconType = keyof typeof icons;
export type IconSize = keyof typeof sizes;

export interface IconProps {
  interactive?: boolean;
  label?: string;
  type: IconType;
  color?: Color;
  onPress?: () => void;
  size?: IconSize;
  disabled?: boolean;
  contained?: boolean;
  ariaLabel?: string;
}

const StyledIcon = styled.button<{
  $color: Color;
  $size: IconSize;
  $interactive: boolean;
  $contained: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5000px;
  background-color: transparent;
  stroke: ${({ theme, $color }) => theme.colors[$color]};
  border: none;
  padding: 0;
  cursor: ${(p) => (p.$interactive ? 'pointer' : 'default')};

  ${(p) =>
    p.$contained &&
    `  
    height: ${sizes[p.$size].container}px;
    width: ${sizes[p.$size].container}px;
  `}

  &:focus-visible {
    ${(p) =>
      p.$interactive &&
      `
      box-shadow: 0 0 0 2px ${p.theme.colors.system};
      outline: none;
    `}
  }

  &:active {
    ${(p) =>
      p.$interactive &&
      `
      opacity: 0.5;
    `}
  }

  &:hover {
    ${(p) =>
      p.$interactive &&
      `
      background-color: ${p.theme.colors.bg3};
    `}
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

function IconComponent(
  {
    label,
    type,
    color = Color.fg,
    size = 'regular',
    onPress,
    interactive = true,
    contained = true,
    disabled,
    ariaLabel,
  }: IconProps,
  ref: React.Ref<HTMLButtonElement>,
) {
  const { accentColor } = useLayoutContext();

  const handlePress = () => {
    if (onPress && !disabled) {
      onPress();
    }
  };

  const IconElement = icons[type];

  const getButtonAriaLabel = () => {
    switch (type) {
      case 'Info':
        return ariaLabel || type;
      case 'CloseCircle':
        return 'Close';
      default:
        return type;
    }
  };

  const buttonAriaLabel = getButtonAriaLabel();

  return (
    <IconLabel label={label}>
      <StyledIcon
        as={interactive ? 'button' : 'span'} // prevent nested button elements
        type={interactive ? 'button' : undefined}
        ref={interactive ? ref : null}
        $color={accentColor === 'aetna' ? 'aetna' : color}
        $size={size}
        $interactive={interactive}
        $contained={contained}
        disabled={disabled}
        aria-label={interactive ? buttonAriaLabel : undefined}
        onClick={handlePress}
        onKeyPress={({ key }) => {
          if (key === 'Enter') handlePress();
        }}
      >
        <IconElement
          aria-hidden="true"
          viewBox="0 0 25 24"
          width={sizes[size].icon}
          height={sizes[size].icon}
        />
        {label && <span className="sr-only">{label}</span>}
      </StyledIcon>
    </IconLabel>
  );
}

export const Icon = React.forwardRef(IconComponent);
