import { Trinary } from '@app/data';
import { optionPresets } from '@app/forms';
import { currentYear, Env } from '@app/utils';

const PREFIX = 'catch.ede.HealthEnrollment';

export const fieldsByState = {
  NJ: [
    {
      copy: PREFIX,
      name: 'isRenewalAgreement',
      type: 'radio',
      required: true,
      options: optionPresets.AGREE_DISAGREE_TRINARY,
      label: { id: '252_Q_NJ' },
      testID: '252_NJ',
    },
    {
      copy: PREFIX,
      name: 'isTerminateCoverageOtherMecFoundAgreement',
      type: 'checkbox',
      required: true,
      label: { id: '255_Q_NJ' },
      testID: '255_NJ',
    },
    {
      copy: PREFIX,
      name: 'aptcAgreement',
      type: 'checkbox',
      required: true,
      label: { id: 'APTC_NJ' },
      testID: 'APTC_NJ',
    },
    {
      copy: PREFIX,
      name: 'isChangeInformationAgreement',
      type: 'checkbox',
      required: true,
      label: { id: '254_Q_NJ' },
      testID: '254_NJ',
    },
    {
      copy: PREFIX,
      name: 'isMedicaidRequirementAgreement',
      type: 'checkbox',
      required: true,
      label: { id: 'ESTATE_NJ' },
      testID: 'ESTATE_NJ',
    },
    {
      copy: PREFIX,
      name: 'isPenaltyOfPerjuryAgreement',
      type: 'checkbox',
      required: true,
      label: { id: '256_Q_NJ' },
      testID: '256_NJ',
    },
    {
      copy: PREFIX,
      name: 'signatureText',
      type: 'signature',
      dependencies: ['legalName'],
      required: true,
      label: { id: '258_Q' },
      testID: '258_NJ',
    },
  ],
  PA: [
    {
      copy: PREFIX,
      name: 'incarcerationAgreement',
      type: 'radio',
      required: true,
      options: [
        {
          label:
            'No. No one listed on this health insurance application is incarcerated (in prison or jail).',
          value: 'NO',
        },
        {
          label: 'Yes',
          value: 'YES',
        },
      ],
      label: { id: 'JAIL_PA' },
      testID: 'JAIL_PA',
    },
    {
      copy: PREFIX,
      name: 'renewalLabel',
      type: 'label',
      label: { id: '252_Q_PA' },
      testID: '252_PA',
    },
    {
      copy: PREFIX,
      name: 'renewalYears',
      type: 'radio',
      required: true,
      label: { id: '252_L_PA' },
      testID: '252_PA',
      options: [
        {
          label: '5 years (the maximum number of years allowed)',
          value: 5,
        },
        {
          label: '4 years',
          value: 4,
        },
        {
          label: '3 years',
          value: 3,
        },
        {
          label: '2 years',
          value: 2,
        },
        {
          label: '1 years',
          value: 1,
        },
        {
          label: 'I do not give Pennie consent to use my income data at renewal.',
          value: Trinary.No,
        },
      ],
    },
    {
      copy: PREFIX,
      name: 'isChangeInformationAgreement',
      type: 'checkbox',
      required: true,
      label: { id: '254_Q_NJ' },
      testID: '254_NJ',
    },
    {
      copy: PREFIX,
      name: 'isMedicaidDetermination',
      type: 'checkbox',
      required: true,
      label: { id: 'MEDICAID_PA' },
      testID: 'MEDICAID_PA',
    },
    {
      copy: PREFIX,
      name: 'isMedicaidRequirementAgreement',
      type: 'checkbox',
      required: true,
      label: { id: 'ESTATE_PA' },
      testID: 'ESTATE_PA',
    },
    {
      copy: PREFIX,
      name: 'voterRegistration',
      type: 'label',
      label: { id: 'VOTER_PA' },
      testID: 'VOTER_PA',
    },
    {
      copy: PREFIX,
      name: 'organDonor',
      type: 'label',
      label: { id: 'DONOR_PA' },
      testID: 'DONOR_PA',
    },
    {
      copy: PREFIX,
      name: 'isInformationCorrect',
      type: 'checkbox',
      required: true,
      label: { id: '256_Q_PA-1' },
      testID: '256_PA',
    },
    {
      copy: PREFIX,
      name: 'isPenaltyOfPerjuryAgreement',
      type: 'checkbox',
      required: true,
      label: { id: '256_Q_PA-2' },
      testID: '256_PA',
    },
    {
      copy: PREFIX,
      name: 'signatureText',
      type: 'signature',
      dependencies: ['legalName'],
      required: true,
      label: { id: '258_Q' },
      testID: '258_NJ',
    },
  ],
  KY: [
    {
      copy: PREFIX,
      name: 'kyTerms',
      type: 'label',
      label: { id: 'TERMS_KY' },
      testID: 'TERMS_KY',
    },
    {
      copy: PREFIX,
      name: 'kyTermsAgreement',
      type: 'checkbox',
      required: true,
      label: { id: 'TERMS_KY_CHECKBOX' },
      testID: 'TERMS_KY_CHECKBOX',
    },
    {
      copy: PREFIX,
      name: 'isRenewalAgreement',
      type: 'radio',
      required: true,
      options: optionPresets.AGREE_DISAGREE_TRINARY,
      label: { id: '252_Q_KY' },
      testID: '252_KY',
    },
    {
      copy: PREFIX,
      name: 'isTerminateCoverageOtherMecFoundAgreement',
      type: 'radio',
      required: true,
      options: optionPresets.AGREE_DISAGREE_TRINARY,
      label: { id: '255_Q_PROD' },
      testID: '255_KY',
    },
    {
      copy: PREFIX,
      name: 'isKYEmployee',
      type: 'radio',
      required: true,
      options: optionPresets.YES_NO_TRINARY,
      label: { id: 'EMPLOYEE_KY' },
      testID: 'EMPLOYEE_KY',
    },

    {
      copy: PREFIX,
      name: 'signatureText',
      type: 'signature',
      dependencies: ['legalName'],
      required: true,
      label: { id: 'SIGNATURE_KY' },
      testID: '258',
    },
  ],
};

export const fields = [
  {
    copy: PREFIX,
    name: 'isRenewalAgreement',
    type: 'radio',
    required: true,
    options: optionPresets.AGREE_DISAGREE_TRINARY,
    dependencies: ['isRequestingFinancialAssistance'],
    display: ({ isRequestingFinancialAssistance }) => !!isRequestingFinancialAssistance,
    label: { id: '252_Q' },
    help: { id: '252_T' },
    testID: '252',
  },
  {
    copy: PREFIX,
    name: 'renewEligibilityYearQuantity',
    type: 'dropdown',
    required: true,
    options: optionPresets.RENEWAL_YEARS,
    dependencies: ['isRenewalAgreement'],
    display: ({ isRenewalAgreement }) => isRenewalAgreement === 'NO',
    label: { id: '253_Q' },
    help: { id: '253_T' },
    testID: '253',
  },
  {
    copy: PREFIX,
    name: 'isMedicaidRequirementAgreement',
    type: 'radio',
    required: true,
    options: optionPresets.AGREE_DISAGREE_TRINARY,
    dependencies: ['members', 'isResubmit', 'coverageState'],
    // until we know that prelim flags are set properly via the import,
    // we should show this question anytime we're in the resubmit style flow
    display: ({ members, isResubmit, coverageState }) =>
      coverageState !== 'CA' &&
      (isResubmit ||
        members?.some(
          (dep) =>
            dep?.preliminaryMedicaidStatus === 'YES' ||
            (dep?.preliminaryEmergencyMedicaidStatus === 'YES' &&
              dep?.preliminaryMedicaidStatus === 'NO'),
        )),
    label: { id: '247_Q' },
    help: { id: '247_T' },
    testID: '247',
  },
  {
    copy: PREFIX,
    name: 'isAbsentParentAgreement',
    type: 'radio',
    required: true,
    options: optionPresets.AGREE_DISAGREE_TRINARY,
    dependencies: ['members', 'coverageState'],
    display: ({ members, coverageState }) => {
      const childDependents = members?.filter((d) => d?.relation === 'CHILD');
      return coverageState !== 'CA' && childDependents?.some((dep) => !!dep?.hasAbsentParent);
    },
    label: { id: '248_Q' },
    help: { id: '248_T' },
    testID: '248',
  },
  {
    copy: PREFIX,
    name: 'isTerminateCoverageOtherMecFoundAgreement',
    type: 'radio',
    required: true,
    dependencies: ['members', 'isGA', 'coverageState', 'assumeEligibility'],
    options: ({ coverageState }) => {
      const labels = {
        CA: [
          'I agree to allow Covered California to end the coverage for anyone on my application who’s enrolled in both Covered California and Medicare coverage.',
          '​I don’t give Covered California permission to end coverage for anyone on my application who’s enrolled in both Covered California and Medicare coverage. I understand that they will have a Covered California health plan without financial help and pay the full cost for their share of the Covered California plan premium and covered services, if eligible.',
        ],
        GA: [
          'I agree to allow Georgia Access to end Georgia Access coverage for anyone on my application who’s enrolled in both Georgia Access and Medicare coverage.',
          '​I don’t give Georgia Access permission to end Georgia Access coverage for anyone on my application who’s enrolled in both Georgia Access and Medicare coverage. I understand that they will have a Georgia Access health plan without financial help and pay the full cost for their share of the Georgia Access plan premium and covered services, if eligible.',
        ],
        fallback: [
          'I agree to allow the Marketplace to end the Marketplace coverage for anyone on my application who’s enrolled in both Marketplace and Medicare coverage.',
          '​I don’t give the Marketplace permission to end Marketplace coverage for anyone on my application who’s enrolled in both Marketplace and Medicare coverage. I understand that they will have a Marketplace health plan without financial help and pay the full cost for their share of the Marketplace plan premium and covered services, if eligible.',
        ],
      };

      const options = coverageState in labels ? labels[coverageState] : labels.fallback;

      return [
        {
          label: options[0],
          value: 'YES',
        },
        {
          label: options[1],
          value: 'NO',
        },
      ];
    },
    display: ({ members, coverageState, assumeEligibility }) =>
      members?.some((dep) => dep?.preliminaryQHPStatus === 'YES') ||
      coverageState === 'CA' ||
      assumeEligibility,
    label: { id: Env.isProd ? '255_Q_PROD' : '255_Q' },
    help: ({ isGA }) => ({ id: `255_T${isGA ? '_GA' : ''}` }),
    testID: '255',
  },
  {
    copy: PREFIX,
    name: 'isChangeInformationAgreement',
    type: 'radio',
    required: true,
    options: optionPresets.AGREE_DISAGREE_TRINARY,
    label: { id: '254_Q' },
    help: { id: '254_T' },
    testID: '254',
    dependencies: ['isChangeInformationAgreement'],
    fieldAddition: ({ isChangeInformationAgreement }) => {
      if (isChangeInformationAgreement === 'NO') {
        return {
          component: 'WARNING',
          props: { copy: { id: 'ede.HealthEnrollment.257_T' } },
        };
      }
    },
  },
  {
    copy: PREFIX,
    name: 'GA_SIG_1',
    type: 'radio',
    options: [{ label: 'I agree to this statement', value: true }],
    required: true,
    dependencies: ['isGA'],
    display: ({ isGA }) => isGA,
    label: { id: 'GA_SIG_1' },
  },
  {
    copy: PREFIX,
    name: 'GA_SIG_2',
    type: 'radio',
    options: [{ label: 'I agree to this statement', value: true }],
    required: true,
    dependencies: ['isGA'],
    display: ({ isGA }) => isGA,
    label: { id: 'GA_SIG_2' },
  },
  {
    copy: PREFIX,
    name: 'isTaxCreditReconciled',
    type: 'checkbox',
    required: false,
    optional: true,
    dependencies: ['legalName', 'preliminaryAPTCStatus', 'year1', 'year2'],
    display: ({ preliminaryAPTCStatus }) => preliminaryAPTCStatus === 'YES',
    precontentTitle: ({ legalName, year1, year2 }) => ({
      id: '236_Q',
      data: { fullName: legalName, year1, year2, lastYear: currentYear - 1 },
    }),
    label: ({ year1, year2 }) => ({
      id: '236_Q_label',
      data: { year1, year2 },
    }),
    help: { id: '236_T' },
    testID: '236',
  },
  {
    copy: PREFIX,
    name: 'isPenaltyOfPerjuryAgreement',
    type: 'checkbox',
    required: true,
    dependencies: ['isGA'],
    label: ({ isGA }) => ({ id: isGA ? '256_Q_GA' : '256_Q' }),
    testID: '256',
  },
  {
    copy: PREFIX,
    name: 'signatureText',
    type: 'signature',
    dependencies: ['legalName'],
    required: true,
    label: { id: '258_Q' },
    testID: '258',
  },
];
