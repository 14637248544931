import { Trinary } from '@app/data';
import { HealthApplicationQuery, HealthPathwayType } from '@app/data/types';
import { boolToTri, currentYear, lastTuesdayOfJuly, safeFormatDate } from '@util';
import { isBefore } from 'date-fns';

export const getInitialValues = (data: HealthApplicationQuery, isResubmit, isGA) => {
  const application = data?.viewerTwo?.health?.application;

  // for question #236, we need to use earlier dates until the IRS
  // updates data on the last Tuesday in July
  const useEarlierYears = isBefore(new Date(), lastTuesdayOfJuly());
  const year1 = currentYear - (useEarlierYears ? 3 : 2);

  console.log('Setting initial values', {
    applicant: application?.applicant,
    legalName: application?.applicant?.legalName,
  });

  const legalName = `${application?.applicant?.givenName} ${application?.applicant?.familyName}`;

  // for state app users, assume that they're eligible for QHP by default
  const assumeEligibility = application?.pathway === HealthPathwayType.StateApp;

  return {
    isGA,
    year1,
    year2: year1 + 1,
    isResubmit,
    assumeEligibility,
    coverageState: application?.coverageState,
    preliminaryAPTCStatus: application?.applicant?.preliminaryAPTCStatus,
    isRequestingFinancialAssistance: application?.screening?.isRequestingFinancialAssistance,
    members: [application?.applicant, ...(application?.members || [])],
    legalName,
    isRenewalAgreement: boolToTri(application?.isRenewalAgreement || (isGA ? true : undefined)),
    renewEligibilityYearQuantity:
      application?.renewEligibilityYearQuantity || (isGA ? 5 : undefined),
    isMedicaidRequirementAgreement: boolToTri(application?.isMedicaidRequirementAgreement),
    isAbsentParentAgreement: boolToTri(application?.isAbsentParentAgreement),
    isTerminateCoverageOtherMecFoundAgreement: boolToTri(
      application?.isTerminateCoverageOtherMecFoundAgreement,
    ),
    isChangeInformationAgreement: boolToTri(application?.isChangeInformationAgreement),
    isPenaltyOfPerjuryAgreement: application?.isPenaltyOfPerjuryAgreement,
    isTaxCreditReconciled: application?.isTaxCreditReconciled,
  };
};

export const formatPayload = ({ applicationID, values, isResubmit, preliminaryAPTCStatus }) => {
  const safeBoolToTri = (val) => {
    return typeof val === 'boolean' ? boolToTri(val) : val;
  };

  // handles for special PA case
  const isRenewalAgreement = values?.renewalYears
    ? typeof values?.renewalYears === 'number'
      ? Trinary.Yes
      : Trinary.No
    : values?.isRenewalAgreement;

  const renewEligibilityYearQuantity = values?.renewalYears
    ? typeof values?.renewalYears === 'number'
      ? parseInt(values?.renewalYears)
      : 0
    : !isRenewalAgreement
    ? null
    : isRenewalAgreement === Trinary.No
    ? parseInt(values?.renewEligibilityYearQuantity, 10)
    : 5;

  // handles setting defaults for required fields that are not shown in state agreements
  if (values?.coverageState === 'KY') {
    values['isPenaltyOfPerjuryAgreement'] = true;
    values['isChangeInformationAgreement'] = true;
  }

  return {
    variables: {
      applicationID,
      minimalEnsure: isResubmit,
      input: {
        id: values?.id,
        lastUsedRoute: 'agreements',
        isRenewalAgreement,
        renewEligibilityYearQuantity,
        isTaxCreditReconciled:
          preliminaryAPTCStatus !== 'YES' ? null : values?.isTaxCreditReconciled ? 'YES' : 'NO',
        isMedicaidRequirementAgreement: safeBoolToTri(values?.isMedicaidRequirementAgreement),
        isAbsentParentAgreement: values?.isAbsentParentAgreement,
        isTerminateCoverageOtherMecFoundAgreement: safeBoolToTri(
          values?.isTerminateCoverageOtherMecFoundAgreement,
        ),
        isChangeInformationAgreement: safeBoolToTri(values?.isChangeInformationAgreement),
        isPenaltyOfPerjuryAgreement: boolToTri(values?.isPenaltyOfPerjuryAgreement),
        signatureText: values?.signatureText,
        signatureDate: safeFormatDate(new Date(), 'yyyy-MM-dd'),
        signatureType: 'APPLICANT',
      },
    },
  };
};
